const DoubleCalls = {

  formLoadHistory: function() {
    let ua_id = this.formGetUaid();
    if (ua_id == null) {
      $(".rwc-DC-editFormHistory").html(this.selectPerson);
      return;
    }
    $(".rwc-DC-editFormHistory").html(this.loading);
    $.ajax({
      url: '/double_calls/load_history',
      dataType: "script",
      data: { dc_id: this.dc_id, ua_id: ua_id }
    });
  },

  formLoadCategories: function() {
    let ua_id = this.formGetUaid();
    if (ua_id == null) {
      $(".rwc-DC-editFormCategories").html(this.selectPerson);
      return;
    }
    $(".rwc-DC-editFormCategories").html(this.loading);
    $.ajax({
      url: '/double_calls/load_entries',
      dataType: "script",
      data: { dc_id: this.dc_id, ua_id: ua_id, ids: this.category_ids }
    });
  },

  formGetUaid: function() {
    let i = $("#dc_double_call_rep_assignment_id").val();
    return (i === "") ? null : i;
  },

  initEditForm: function(dc_id, category_ids) {
    //console.log('DC initEditForm', dc_id, category_ids);
    this.dc_id = dc_id;
    this.category_ids = category_ids;
    this.loading = $('.rwc-DC-mesLoading').text();
    this.selectPerson = $('.rwc-DC-mesSelectPerson').text();

    $("#dc_double_call_rep_assignment_id").change((ev) => {
      this.formLoadHistory();
      this.formLoadCategories();
    }).change();
  },

  initReportForm: function() {
    $(".rwc-DC-saveToPreview").click(function() {
      $("#dc_save_to").val("preview");
    });

    $(".rwc-DC-saveToEntries").click(function() {
      $("#dc_save_to").val("entries");
    });
  }
};

export default DoubleCalls;
