/* eslint no-console:0 */

import $ from 'jquery';
global.$ = $;
global.jQuery = $;

import 'bootstrap/dist/js/bootstrap'
import '../lib/jquery.gritter/js/jquery.gritter';
import '../lib/beagle-js/jquery-breakpoint-check';
import '../lib/beagle-js/jquery.touchSwipe';
import '../lib/datepicker/js/bootstrap-datepicker'
import '../lib/treetable/jquery.treetable';
import '../lib/treetable/jquery.treetable.scss';
import '../lib/select2/js/select2'
import '../lib/select2/js/i18n/pl'
import '../lib/select2/css/select2.css'

import Rails from '@rails/ujs';
global.Rails = Rails;
Rails.start();

import Turbolinks from 'turbolinks';
Turbolinks.start();

import SideNav from '../lib/beagle-js/sidenav';
SideNav.start();

import refwell from '../js/refwell';
global.refwell = refwell;
refwell.start();

import UnobtrusiveFlash from '../js/flash-messages';
UnobtrusiveFlash.start();
global.UnobtrusiveFlash = UnobtrusiveFlash;

import RwForms from '../js/rw_forms';
global.RwForms = RwForms;

import '../lib/perfect-scrollbar/css/perfect-scrollbar.css';
import '../lib/jquery.gritter/css/jquery.gritter.css';
import '../lib/datepicker/css/datepicker.css';
import '../lib/beagle-sass/app.scss';

import '../css/admin.scss'
import '../css/application.scss'
import '../css/calendar.scss'
import '../css/cart.scss'
import '../css/inst-search.scss'
import '../css/loc-day-report.scss'
import '../css/loc-summaries.scss'
import '../css/merger.scss'
import '../css/person-search.scss'
import '../css/reports.scss'
import '../css/visits.scss'
import '../css/double_calls.scss'

import '../images/rw_launcher.png'
import '../images/visit_missed.png'
import '../images/visit_p_reported.png'
import '../images/visit_reported.png'
import '../images/visit_planed.png'
import '../lib/beagle-img/avatar.png'
import '../lib/beagle-img/avatar2.png'
import '../images/map-markers/marker.png'
import '../images/map-markers/marker_black.png'
import '../images/map-markers/marker_green.png'
import '../images/map-markers/marker_grey.png'
import '../images/map-markers/marker_orange.png'
import '../images/map-markers/marker_white.png'
import '../images/map-markers/marker_yellow.png'
import '../images/map-markers/shadow50.png'

import '../images/help/activity-report.jpg'
import '../images/help/cal-week-activity.jpg'
import '../images/help/person-search.jpg'
import '../images/help/inst-new-button.jpg'
import '../images/help/person-new-button.jpg'
import '../images/help/person-new-form.jpg'
import '../images/help/inst-new-form.jpg'
import '../images/help/workplace-new-form.jpg'
import '../images/help/workplace-edit-button.jpg'
import '../images/help/mobi-start-screen.jpg'
import '../images/help/mobi-activity-report.jpg'
